//
//  15sec1ansoptions.js
//  15Sec1Ans Options
//
//  Created by Tevon Wallace on 02/03/23.
//  Copyright © 2023 Tevon Wallace. All rights reserved.
//

import lightModeFirstiPhoneImage from '../../../../assets/images/projects/apps/15_sec_1_ans/iPhone/light_mode/1.png'
import lightModeSecondiPhoneImage from '../../../../assets/images/projects/apps/15_sec_1_ans/iPhone/light_mode/2.png'
import lightModeThirdiPhoneImage from '../../../../assets/images/projects/apps/15_sec_1_ans/iPhone/light_mode/3.png'

export const get15Sec1AnsOptions = (mode) => {
    return {
        iPhoneImages: [
            {
                title: null,
                image: lightModeFirstiPhoneImage /* isDarkMode ? darkModeFirstiPhoneImage : lightModeFirstiPhoneImage*/
            },
            {
                title: null,
                image: lightModeSecondiPhoneImage /* isDarkMode ? darkModeSecondiPhoneImage : lightModeSecondiPhoneImage */
            },
            {
                title: null,
                image: lightModeThirdiPhoneImage /* isDarkMode ? darkModeThirdiPhoneImage : lightModeThirdiPhoneImage */
            }
        ],
        iPadImages: [],
        additionalDescriptions: [
            "🎵 Listen to entertaining short clips and put your knowledge to the test.",
            "📈 Climb the ranks and earn points by submitting your answers.",
            "🎙️ Unlock exclusive “Voice of the Day” clips for bonus rewards.",
            "💡 Challenge yourself daily with exciting trivia questions.",
            "🏆 Sharpen your skills, rise to the top, and become the ultimate champion!"
        ]
    }
}
