//
//  apps.js
//  Apps
//
//  Created by Tevon Wallace on 03/09/21.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import { pages } from "../../../constants/constants"

export const getAppVersions = (app) => {
    if (app === pages[4]) { // Visual Editor
        return {
            versions: [
                { title: "1.2.9", value: "1.2.9" },
                { title: "1.3.0", value: "1.3.0" },
                { title: "1.3.1", value: "1.3.1" },
            ],
            default: "1.3.1"
        }
    }
    else if (app === pages[7]) { // REST Client
        return {
            versions: [
                { title: "1.1.9", value: "1.1.9" },
                { title: "1.2.0", value: "1.2.0" },
                { title: "1.2.1", value: "1.2.1" },
            ],
            default: "1.2.1"
        }
    }
    else if (app === pages[10]) { // Ryde Safe
        return {
            versions: [
                { title: "1.4.0", value: "1.4.0" },
            ],
            default: "1.4.0"
        }
    }
    else if (app === pages[14]) { // 15 Sec 1 Ans
        return {
            versions: [
                { title: "1.0.0", value: "1.0.0" },
                { title: "1.0.1", value: "1.0.1" },
            ],
            default: "1.0.1"
        }
    }
    else {
        return {
            versions: [
                { title: "0.0.0", value: "0.0.0" },
            ],
            default: "0.0.0"
        }
    }
}