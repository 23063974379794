//
//  constants.js
//  Constants
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

// MARK: - Environmental Constants
export const homepage = process.env.REACT_APP_HOME_PAGE
export const resumePage = process.env.REACT_APP_RESUME_PAGE
export const projectsPage = process.env.REACT_APP_PROJECTS_PAGE

export const visualFileEditorPage = process.env.REACT_APP_VISUAL_FILE_EDITOR_PAGE
export const visualFileEditorSupportPage = process.env.REACT_APP_VISUAL_FILE_EDITOR_SUPPORT_PAGE
export const visualFileEditorPageSupportForm = process.env.REACT_APP_VISUAL_FILE_EDITOR_SUPPORT_FORM
export const visualFileEditorFaviconLink = process.env.REACT_APP_VISUAL_FILE_EDITOR_FAVICON_LINK

export const restAPIClientPage = process.env.REACT_APP_REST_API_CLIENT_PAGE
export const restAPIClientSupportPage = process.env.REACT_APP_REST_API_CLIENT_SUPPORT_PAGE
export const restAPIClientPageSupportForm = process.env.REACT_APP_REST_API_CLIENT_SUPPORT_FORM
export const restAPIClientFaviconLink = process.env.REACT_APP_REST_API_CLIENT_FAVICON_LINK

export const rydeSafePage = process.env.REACT_APP_RYDE_SAFE_PAGE
export const rydeSafeSupportPage = process.env.REACT_APP_RYDE_SAFE_SUPPORT_PAGE
export const rydeSafePageSupportForm = process.env.REACT_APP_RYDE_SAFE_SUPPORT_FORM
export const rydeSafeFaviconLink = process.env.REACT_APP_RYDE_SAFE_FAVICON_LINK

export const fifteenSec1AnsPage = process.env.REACT_APP_15_SEC_1_ANS_PAGE
export const fifteenSec1AnseSupportPage = process.env.REACT_APP_15_SEC_1_ANS_SUPPORT_PAGE
export const fifteenSec1AnsSupportForm = process.env.REACT_APP_15_SEC_1_ANS_SUPPORT_FORM
export const fifteenSec1AnsFaviconLink = process.env.REACT_APP_15_SEC_1_ANS_FAVICON_LINK

export const contactsPage = process.env.REACT_APP_CONTACTS_PAGE
export const businessPage = process.env.REACT_APP_BUSINESS_PAGE
export const photosPage = process.env.REACT_APP_PHOTOS_PAGE
export const videosPage = process.env.REACT_APP_VIDEOS_PAGE
export const viewData = process.env.REACT_APP_RECORD_VIEW_DATA

export const googleAdMobAppAdsKey = process.env.REACT_APP_GOOGLE_AD_MOB_APP_ADS_LINK
export const middleWareApiUrl = process.env.REACT_APP_MIDDLEWARE_URL
export const submitAPIKey = process.env.REACT_APP_SUBMIT_API_KEY

export const visualFileEditorSupportFormAPIKey = process.env.REACT_APP_VISUAL_FILE_EDITOR_SUPPORT_FORM_API_KEY
export const restAPIClientSupportFormAPIKey = process.env.REACT_APP_REST_API_CLIENT_SUPPORT_FORM_API_KEY
export const rydeSafeSupportFormAPIKey = process.env.REACT_APP_RYDE_SAFE_SUPPORT_FORM_API_KEY
export const fifteenSec1AnsFormAPIKey = process.env.REACT_APP_15_SEC_1_ANS_SUPPORT_FORM_API_KEY

export const submitContactFormUrl = process.env.REACT_APP_SUBMIT_CONTACT_FORM_URL

export const visualFileEditorSupportFormUrl = process.env.REACT_APP_VISUAL_FILE_EDITOR_SUPPORT_FORM_URL
export const restAPIClientSupportFormUrl = process.env.REACT_APP_REST_API_CLIENT_SUPPORT_FORM_URL
export const rydeSafeSupportFormUrl = process.env.REACT_APP_RYDE_SAFE_SUPPORT_FORM_URL
export const fifteenSec1AnsSupportFormUrl = process.env.REACT_APP_15_SEC_1_ANS_SUPPORT_FORM_URL

export const resumeDownloadLink = process.env.REACT_APP_RESUME_DOWNLOAD_LINK

export const PRODUCTION_ENVIRONMENT = "production"

// MARK: - External Links
export const visualFileEditorAppStoreLink = "https://apps.apple.com/us/app/visual-file-editor/id1534296097"
export const restAPIClientAppStoreLink = "https://apps.apple.com/us/app/rest-api-client/id1583154375"
export const rydeSafeAppStoreLink = "https://apps.apple.com/us/app/ryde-safe-affordable-secure/id6443764459"
export const rydeSafePlayStoreLink = "https://play.google.com/store/apps/details?id=com.fusiontech.rydesafe"
export const rydeSafeWebsiteLink = "https://rydesafe.net"
export const fifteenSec1AnsWebsiteLink = "https://apps.apple.com/us/app/15-sec-1-ans/id1494698605"

export const heartBeatRemixLink = "https://youtu.be/suvPv9vLb4Q"
export const heartBeatRemixPath = "/heartbeat-remix"

export const grabbaCabLink = "https://apps.apple.com/jm/app/grabba-cab/id1330423115"
export const uplaydLink = "https://www.uplayd.com"

// MARK: - Different pages in the application
export const home = "/"
export const business = '/business'
export const photos = '/photos'
export const videos = '/videos'
export const projects = "/projects"
export const resume = "/resume"
export const contacts = "/contacts"
export const apps = "/apps"
export const support = "/support"

export const websiteName = "Tevon Wallace";

export const appNames = ["visualfileeditor", "restapiclient", "rydesafe", "15sec1ans"]

export const listOfApps = [
    { app: `${apps}/${appNames[0]}`, support: `${apps}/${appNames[0]}${support}`, downloadLink: `${apps}/${appNames[0]}/download` },
    { app: `${apps}/${appNames[1]}`, support: `${apps}/${appNames[1]}${support}`, downloadLink: `${apps}/${appNames[1]}/download` },
    { app: `${apps}/${appNames[2]}`, support: `${apps}/${appNames[2]}${support}`, downloadLink: `${apps}/${appNames[2]}/download`, webLink: rydeSafeWebsiteLink },
    { app: `${apps}/${appNames[3]}`, support: `${apps}/${appNames[3]}${support}`, downloadLink: `${apps}/${appNames[3]}/download` },
]

export const pages = [
    home, // Home [0]
    resume, // Resume [1]
    contacts, // Contact Us [2]
    listOfApps[0].app, // Visual File Editor's Details [3]
    listOfApps[0].support, // Visual File Editor's Support Form [4]
    listOfApps[0].downloadLink, // Visual File Editor's Download Link [5]
    listOfApps[1].app, // Rest API Client's Details [6]
    listOfApps[1].support, // Rest API Client's Support Form [7]
    listOfApps[1].downloadLink, // Rest API Client's Download Link [8]
    listOfApps[2].app, // Ryde Safe's Details [9]
    listOfApps[2].support, // Ryde Safe's Support Form [10]
    listOfApps[2].downloadLink, // Ryde Safe's Download Link [11]
    listOfApps[2].webLink, // // Ryde Safe's Website Link [12]
    listOfApps[3].app, // 15 Sec 1 Ans's Details [13]
    listOfApps[3].support, // 15 Sec 1 Ans's Support Form [14]
    listOfApps[3].downloadLink, // 15 Sec 1 Ans's Download Link [15]
]

export const appPreviewRoutes = [pages[3], pages[6], pages[9], pages[13]]
export const appPreviewSupportRoutes = [pages[4], pages[7], pages[10], pages[14]]

export const appIndexConstants = {
    visualFileEditor: 0,
    restAPIClient: 1,
    fifteenSec1Ans: 2,
    rydeSafe: 3,
}

export const headerLinks = [
    { title: 'Home', link: home },
    // { title: 'Resume', link: resume }, // Update utils.js, and umcomment lines 89 - 92 when Resume header is enabled
    { title: 'Contacts', link: contacts },
]

export const footerLinks = [
    { title: 'Instagram', link: 'https://instagram.com/tevonwallace' },
    { title: 'Twitter', link: 'https://twitter.com/tevonwallace' },
    { title: 'LinkedIn', link: 'https://linkedin.com/in/tevonwallace' },
    { title: 'YouTube', link: 'https://youtube.com/tevonwallace' }
]
