//
//  projectoptions.js
//  ProjectOptions
//
//  Created by Tevon Wallace on 11/12/20.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import { visualFileEditorAppStoreLink, pages, restAPIClientAppStoreLink, rydeSafeAppStoreLink, rydeSafePlayStoreLink, fifteenSec1AnsWebsiteLink } from '../../constants/constants'
import visualFileEditorImage from '../../assets/images/projects/visualfileeditor.png'
import visualFileEditorRoundedImage from '../../assets/images/projects/visualfileeditor-rounded.png'
import restApiClient from '../../assets/images/projects/restapiclient.png'
import rydeSafeImage from '../../assets/images/projects/rydesafe.png'
import rydeSafeRoundedImage from '../../assets/images/projects/rydesafe-rounded.png'
import restApiClientRoundedImage from '../../assets/images/projects/restapiclient-rounded.png'
import fifteenSec1AnsImage from '../../assets/images/projects/15sec1ans.png'
import fifteenSec1AnsRoundedImage from '../../assets/images/projects/15sec1ans-rounded.png'

export const getProjects = (mode, canSetImagesBasedOnMode = true) => {
    const isDarkMode = canSetImagesBasedOnMode && mode === 'dark'

    return [
        {
            title: "Visual File Editor",
            image: isDarkMode ? visualFileEditorImage : visualFileEditorRoundedImage,
            roundedImage: visualFileEditorRoundedImage,
            description: "Seamless code editing and powerful search for multiple programming languages!",
            links: [{
                title: "App Store",
                description: "Please click the link below to download the application from the App Store or get more information about Visual File Editor.",
                link: visualFileEditorAppStoreLink,
                downloadLinkTitle: "Download",
                additionalLink: {
                    link: pages[4],
                    title: "Support Link"
                }
            }],
            websiteLink: {
                link: pages[3]
            },
        },
        {
            title: "REST API Client",
            image: isDarkMode ? restApiClient : restApiClientRoundedImage,
            description: "Effortless API testing and request management at your fingertips!",
            links: [{
                title: "App Store",
                description: "Please click the link below to download the application from the App Store or get more information about REST API Client.",
                link: restAPIClientAppStoreLink,
                downloadLinkTitle: "Download",
                additionalLink: {
                    link: pages[7],
                    title: "Support Link"
                }
            }],
            websiteLink: {
                link: pages[6]
            },
        },

        {
            title: "15 Sec 1 Ans",
            image: isDarkMode ? fifteenSec1AnsImage : fifteenSec1AnsRoundedImage,
            description: "Test your knowledge, climb the ranks, and become the trivia champion!",
            links: [{
                title: "App Store",
                description: "Please click the link below to download the application from the App Store or get more information about 15 Sec 1 Ans.",
                link: fifteenSec1AnsWebsiteLink,
                downloadLinkTitle: "Download",
                additionalLink: {
                    link: pages[14],
                    title: "Support Link"
                }
            }],
            websiteLink: {
                link: pages[13]
            },
        },
        {
            title: "Ryde Safe",
            image: isDarkMode ? rydeSafeImage : rydeSafeRoundedImage,
            description: "Ryde Safe is the most secure and affordable ride sharing app in Jamaica.",
            links: [{
                title: "App Store",
                description: "Please click the link below to download the application from the App or Play Store or get more information about Ryde Safe.",
                link: rydeSafeAppStoreLink,
                downloadLinkTitle: "Download",
                additionalLink: {
                    link: pages[10],
                    title: "Support Link"
                }
            },
            {
                title: "Play Store",
                description: "Please click the link below to download the application from the App or Play Store or get more information about Ryde Safe.",
                link: rydeSafePlayStoreLink,
                downloadLinkTitle: "Download",
                additionalLink: {
                    link: pages[10],
                    title: "Support Link"
                }
            },
            {
                title: "Website",
                description: "Please click the link below to download the application from the App or Play Store or get more information about Ryde Safe.",
                link: pages[12],
                downloadLinkTitle: "",
                additionalLink: {
                    link: pages[12],
                    title: "Web"
                }
            }],
            websiteLink: {
                link: pages[9]
            },
        }
    ]
}
