//
//  resume.js
//  Resume
//
//  Created by Tevon Wallace on 12/8/19.
//  Copyright © 2020 Tevon Wallace. All rights reserved.
//

import React from 'react'
import { Grid } from '@material-ui/core'
import { Qualifications } from './qualifications/qualifications'
import './resume.scss'
import { toDarkModeName } from '../../utils/utils';
import { getProjects } from '../projects/projectoptions'
import { appIndexConstants } from '../../constants/constants'
import { getVisualFileEditorOptions } from '../app_preview/app_options/visual_file_editor/visualfileeditoroptions'
import { getRestClientOptions } from '../app_preview/app_options/rest_api_client/restapiclientoptions'
import { get15Sec1AnsOptions } from '../app_preview/app_options/15_sec_1_ans/15sec1ansoptions'
import { getRydeSafeOptions } from '../app_preview/app_options/ryde_safe/rydesafeoptions'

class Resume extends React.Component {
    state = {
        resumeDescription: "Results-driven Senior Full-Stack Software Engineer with a passion for cutting-edge technology and a relentless pursuit of excellence, currently engaged in a Doctoral program in Computer Science. Leveraging a robust background in software development, I aim to seamlessly integrate academic insights with practical industry experience to drive innovation and elevate project outcomes. Proven expertise in full-stack developmen combined with a commitment to continuous learning positions me to make significant contributions to high-impact projects and advance the forefront of technology. Seeking a challenging role where I can apply my unique blend of academic rigor and hands-on engineering prowess to propel teams and technologies to new heights.",
        qualifications: {
            title: "Education",
            value: [
                {
                    title: "Zhejiang University, China",
                    levels: ["September, 2022 - Present", "Doctoral Degree"],
                    major: "Major: Computer Science",
                    isCurrent: true
                },
                {
                    title: "Nankai University, China",
                    levels: ["September, 2019 - June, 2021", "Master of Engineering"],
                    major: "Major: Software Engineering",
                    isCurrent: false
                },
                {
                    title: "University of Technology, Jamaica",
                    levels: ["September, 2014 - May, 2018", "Bachelor of Science in Computing"],
                    major: "Major: Computer Science"
                }]
        },
        coCurricularActivities: {
            title: "Co-Curricular Activities",
            value: [{
                title: "Rotaract Club Member, University of Technology",
                levels: ["January 2017 - December 2019"]
            }]
        },
        communityService: {
            title: "Community Service",
            value: [{
                title: "Contributed to the Christiana Police Station",
                levels: ["December 2013"]
            }]
        },
        experience: {
            title: "Professional Experience",
            value: [
                {
                    title: "LTV-SaaS Growth Fund",
                    levels: ["Senior Full-Stack Engineer", "April 2024 - Present", "Performs production maintenance",
                        "Lead development teams, mentor junior engineers", "Implement and features on both frontend and backend codebases", "Optimize code and database queries for performance",
                        "Scalability and security in database management, caching strategies and load balancing."
                    ],
                },
                {
                    title: "TFOB (2021) Limited (Lynk)",
                    levels: ["Senior Backend Developer", "October 2021 - March 2024", "Production maintenance and support for all consumer transactional features.",
                        "Developed, managed, owned and co-owned micro-services and libraries for consumer transactional services.", "Managed deployment plans and distributed high quality services that are used by more than two hundred thousand users."
                        , "Created high throughput encryption and decryption libraries and services used in communication with third party services.",
                        "Improved the quality, security and performance of the backend services to provide maximum efficiency.", "Solved technically challenging problems, developed and provided solutions to complex & time sensitive specifications in architectures.",
                        "Performed daily operations as Senior and Technical Lead, review architectures, support and Production Analysis.",
                        "Developed and maintained most third party client libraries used by the organization.", "Provided mentorship to Junior engineers to grow and expand their technical responsibilities.",
                        "Consistent and excellent performance reviews."],
                },
                {
                    title: "Vertis Technology Solutions Limited",
                    levels: ["Software Developer (Consultant)", "April 2019 - December 2019", "Full-stack engineer who developed micro-services to manage NCB’s credit card online application platform using technologies such as Java SpringBoot, PostgreSQL, and Pivotal Container Service (PKS).",
                        "Developed the credit card web application using ReactJS.", "Provided mentorship to junior engineers.", "Lead both the backend and frontend develop teams.", "Performed the role of a Senior Backend engineer and architect.", "Designed and created the architecture for the backend application.",
                        "Created a security application for Hawkeye using Flutter."]
                },
                {
                    title: "Smart Mobile Solutions Jamaica",
                    levels: ["Software Developer", "October 2018 - March 2019", "February 2018 - April 2018", "Developed in-house .NET applications used by internal staff members at Smart Mobile Solutions.",
                        "Developed middleware services that are used to communicate with merchant point of sale terminals.", "Developed a web application portal using ReactJS to communicate with internal and external services and point of sale systems."]
                },
                {
                    title: "eGov Jamaica Limited, Summer 2016",
                    levels: ["Software Developer", "Performed software updates", "Added an export feature for PDF and Excel", "Removed malfunctioning features and added updated modules"]
                }]
        },
        specialSkills: {
            title: "Key Skills",
            value: [
                {
                    title: "Software development using Swift, C & C++, Java, Spring Boot, Flutter, Native Android Development, Ruby, Python, ReactJS, GatsbyJS, Remix, R, NodeJS, PHP and JavaScript",
                    // levels: ["Swift ", "C", "C++", "Flutter", "Java", "Android Development", "Java Spring Boot",
                    //     "Python", "React.js", "Gatsby.js", "R", "Microsoft .NET Core", "Node.js", "JavaScript"]
                    levels: []
                },
                {
                    title: "Azure DevOps, Google Cloud Platform, Firebase, Kubernetes, Github & Gitlab",
                    levels: []
                },
                {
                    title: "SQL and NoSQL databases",
                    levels: []
                    // levels: ["Azure DevOps", "Google Cloud Platform", "Github", "Gitlab", "Kubernetes", "Firebase"]
                },
                {
                    title: "Machine Learning Researcher / Data Scientist",
                    levels: []
                    // levels: ["SQL", "NoSQL"]
                },
                {
                    title: "Interpersonal & Leadership Skills",
                    levels: []
                },
                {
                    title: "Project Management & Organization",
                    levels: []
                }]
        },
        accomplishments: {
            title: "Accomplishments",
            value: [
                {
                    title: "Development Accomplishment",
                    levels: ["Developed and distributed robust and high performing services that are used by more than 200K users.", "Developed, trained and ran predictions with machine learning models.",
                        "Developed applications using Computer Vision.", "Developed multiple productive applications for iOS and iPadOS.", "Created a source code editor for iOS and iPadOS with more than 20K organic users (Visual File Editor).",
                        "Created social media applications.", "Created a Transport Networking application (REST Client)", "Developed mobile games for iOS and iPadOS using Swift"]
                },
                {
                    title: "Participation Awards",
                    levels: ["NSE 1 & 2 Network Security Associate, FORTINET NSE Institute",
                        "Certificate of Participation, QualityWorks hackathon",
                        "Certificate of Membership, Rotaract Club",
                        "Certificate of Participation, eGov Jamaica Limited"]
                }]
        },
        personalProjects: {
            title: "Personal Projects",
            value: [
                {
                    title: "Visual File Editor",
                    downloadLink: getProjects('dark')[appIndexConstants.visualFileEditor].links[0].link,
                    levels: [getVisualFileEditorOptions('dark').additionalDescriptions[0],
                    getVisualFileEditorOptions('dark').additionalDescriptions[1],
                    getVisualFileEditorOptions('dark').additionalDescriptions[2],
                    getVisualFileEditorOptions('dark').additionalDescriptions[3],
                        "Download Link"]
                },
                {
                    title: "REST API Client",
                    downloadLink: getProjects('dark')[appIndexConstants.restAPIClient].links[0].link,
                    levels: [getRestClientOptions('dark').additionalDescriptions[0],
                    getRestClientOptions('dark').additionalDescriptions[1],
                    getRestClientOptions('dark').additionalDescriptions[2],
                    getRestClientOptions('dark').additionalDescriptions[3],
                        "Download Link"]
                },
                {
                    title: "15 Sec 1 Ans",
                    downloadLink: getProjects('dark')[appIndexConstants.fifteenSec1Ans].links[0].link,
                    levels: [get15Sec1AnsOptions('dark').additionalDescriptions[0],
                    get15Sec1AnsOptions('dark').additionalDescriptions[1],
                    get15Sec1AnsOptions('dark').additionalDescriptions[2],
                    get15Sec1AnsOptions('dark').additionalDescriptions[3],
                        "Download Link"]
                },
                {
                    title: "Ryde Safe",
                    downloadLink: getProjects('dark')[appIndexConstants.rydeSafe].links[0].link,
                    levels: [getRydeSafeOptions('dark').additionalDescriptions[0],
                    getRydeSafeOptions('dark').additionalDescriptions[1],
                    getRydeSafeOptions('dark').additionalDescriptions[2],
                    getRydeSafeOptions('dark').additionalDescriptions[3],
                        "Download Link"]
                }]
        },
        personalSkills: {
            title: "Personal Skills",
            value: [{
                title: "Excellent IT and problem solving skills",
                levels: []
            },
            {
                title: "Success and result driven",
                levels: []
            },
            {
                title: "Critical thinking abilities",
                levels: []
            },
            {
                title: "Able to work individually or in a team",
                levels: []
            }]
        },
        interests: {
            title: "Interests",
            value: [{
                title: "Computer Science",
                levels: []
            },
            {
                title: "Apple",
                levels: []
            },
            {
                title: "Physics",
                levels: []
            },
            {
                title: "Networking",
                levels: []
            }],
            secondList: [
                {
                    title: "Research",
                    levels: []
                },
                {
                    title: "Self Discipline",
                    levels: []
                },
                {
                    title: "Credit Cards",
                    levels: []
                },
                {
                    title: "Music",
                    levels: []
                },]
        }
    }

    render() {
        const { mode } = this.props
        const { resumeDescription, qualifications, experience, coCurricularActivities, communityService, specialSkills, accomplishments, personalProjects, personalSkills, interests } = this.state

        return (
            <div>
                <Grid container justify={"space-around"}>
                    <Grid item xs={12} sm={12}>
                        <div className={toDarkModeName(mode, "nameAndDescription")}>
                            <div className={toDarkModeName(mode, "resumeName")}>TEVON WALLACE</div>
                            <div className={toDarkModeName(mode, "resumeDescription")} style={{ marginTop: 0 }}>SENIOR FULL-STACK SOFTWARE ENGINEER</div>
                            <div className={toDarkModeName(mode, "resumeDescription")} style={{ textAlign: "justify", marginLeft: "3.0%", marginRight: "3.0%" }}>{resumeDescription}</div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container justify={"space-around"}>
                    <Grid item xs={12} sm={5}>
                        <Grid container direction={'row'}>
                            <Grid item xs={12}>
                                <Qualifications
                                    data={qualifications}
                                    mode={mode}
                                    isQualifications={true} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Qualifications
                            data={specialSkills}
                            mode={mode} />
                    </Grid>
                </Grid>

                <Grid container justify={"space-around"} style={{ marginBottom: 0 }}>
                    <Grid item xs={12} sm={5}>
                        <Qualifications
                            data={experience}
                            mode={mode} />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Qualifications
                            data={accomplishments}
                            mode={mode} />

                        <Qualifications
                            data={personalProjects}
                            mode={mode} />
                    </Grid>
                </Grid>

                <Grid container justify={"space-around"} style={{ marginBottom: 75 }}>
                    <Grid item xs={12} sm={5}>
                        <Qualifications
                            data={personalSkills}
                            mode={mode} />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Qualifications
                            data={interests}
                            mode={mode} />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Qualifications
                            data={coCurricularActivities}
                            mode={mode} />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <Qualifications
                            data={communityService}
                            mode={mode} />
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default Resume
